export default {
  //试用中心-首页
  '1-1': 'free-trial-index',
  //试用中心-个人中心页
  '1-2': 'free-trial-person-center',
  //试用中心-报告页
  '1-3': 'free-trial-report',
  // 列表页
  '1-4': 'product-list',
  // 详情页
  '1-5': 'product-detail',
  // 相似商品结果页（弹窗）
  '1-6': 'similar-product',
  // 商品详情页
  '1-8': 'goods-detail',
  // 购物车页
  '1-7': 'cart',
  // 闪购页
  '1-9': 'flash-sale',
  // 收藏夹
  '1-10': 'user-wishlist',
  // 下单页
  '1-11': 'place-order',
  // 支付成功页
  '1-12': 'pay-success',
  // 礼品卡详情页
  '1-13': 'giftcard-home',
  // contact us页面
  '1-14': 'contact-us',
  // Me 地址页
  '1-16': 'address',
  // 订单详情
  '1-17': 'order-detail',
  // 订单列表
  '1-18': 'order-list',
  // 二次下单页
  '1-19': 'place-order-again',
  // 专题活动页面
  '1-21': 'config-activity',
  // 个人中心
  '1-22': 'user',
  // 新版列表页
  '1-23': 'product-list-v2',
  '1-24': 'shareandearn',
  // 礼品卡订单详情
  '1-25': 'giftcard-detail',
  // 礼品卡订单列表
  '1-26': 'giftcard-list',
  // 退货页面
  '1-27': 'order-return',
  // 订单评论页面
  '1-28': 'order-review',
  // 卡支付页面
  '1-29': 'payment',
  // 数据主体权利中心页面
  '1-30': 'privacy-request',
  // 部分取消商品页
  '1-31': 'cancel-item',
  // 部分取消商品第二部
  '1-32': 'refund-item',
  // 机器人页面
  '1-33': 'robot',
  // 站内开关页面
  '1-34': 'notification',
  // 合单承接页
  '1-35': 'order-combined',
  // 海关拦截包裹清单页
  '1-36': 'order-customs',
  // 文章页
  '1-37': 'article',
  // cod页面
  '1-38': 'payCod',
  // 发票中心
  '1-39': 'order-invoices',
  // 账号删除
  '1-43': 'deactivate-account',
  // 订单拒收
  '1-42': 'order-reject-reason',
  // romwe vip
  '1-44': 'romweVip',
  // 评论中心
  '1-45': 'review-center',
  // sheinx设计师列表
  '1-46': 'designer_list',
  // 审单页面
  '1-47': 'verification',
  // 分享券活动
  '1-48': 'friendship_coupon',
  // 店铺评论页
  '1-49': 'store-comment',
  // 礼品卡下单页
  '1-50': 'giftcard-checkout',
  // 账户安全页
  '1-51': 'user',
  // 邮箱解绑
  '1-52': 'email_unbind',
  // 手机号换绑
  '1-53': 'identity_verification',
  // 支付结果未知状态页
  '1-54': 'pay-pending',
  // 店铺页面
  '1-55': 'store_pages',
  // 回归用户券包弹窗
  '1-56': 'coupon-revisit',
  // 用户收藏店铺列表落地页
  '1-57': 'user',
  // 营销人群差异化承接页
  '1-58': 'advertising',
  // 新物流轨迹页
  '1-59': 'order-track-new',
  // 评论详情页
  '1-60': 'review-already',
  // 订单相关页面
  '1-61': 'orders',
  // 自主换货相关页面
  '1-62': 'order-self-exchange',
  // 不贵频道页
  '1-63': 'thrifty_find',
  // 品牌频道页
  '1-64': 'brand-zone',
  '1-65': 'find_order',
  '1-66': 'user-news',
  '1-67': 'top-trend',
  // 趋势落地页
  '1-68': 'trend-landing',
  // 公共组件-地址选择
  '2-1': 'address-edit.js',
  // 商品组件的数据上报
  '2-3': 'product-item.js',
  // 地址组件
  '2-4': 'address-vue.js',
  // 推荐组件
  '2-5': 'recommend.js',
  // 专题组件的数据上报
  '2-6': 'act-component.js',
  '2-7': 'login.js',
  // 新quick-view组件
  '2-8': 'quick-view.js',
  // ccc 配置的组件
  '2-10': 'ccc-component.js',
  // 券包弹窗
  '2-11': 'coupon-package.js',
  // 收藏夹分组弹窗
  '2-13': 'dialog-group.js',
  // 悬浮icon
  '2-14': 'suspension-icon.js',
  // 导航栏个人中心下拉框
  '2-15': 'user-dropdown.js',
  // 导航栏
  '2-16': 'category.js',
  // 邮箱验证组件
  '2-17': 'email-validate.js',
  // 现金支付回退倒计时组件
  '2-18': 'back-tip.js',
  // CCC 重构组件
  '2-19': 'ccc.js',
  // 评论相关组件
  '2-20': 'comment.js',
  // 搭配相关
  '2-21': 'outfit.js',
  // 分享相关
  '2-22': 'share-common.js',
  // 一键购推荐列表标题(含支付成功弹窗)
  '2-23': 'one-click-pay',
  // 未满十八岁弹窗
  '2-28': 'age-limit-dialog.js',
  // 地址推荐弹窗
  '2-29': 'recommended-address-dialog.js',
  // 地址确认弹窗
  '2-30': 'confirm-address-dialog.js',
  // 支付通用埋点
  '2-31': 'payment-common.js',
  // fpx支付方式银行升级提醒弹窗
  '2-32': 'fpx-tips-dialog.js',
  // 自定义素材通用埋点
  '2-33': 'custom-content.js',
  // 新样式券包弹窗埋点
  '2-34': 'coupon-package-v2.js',
  // ontrust 弹窗banner埋点
  '2-35': 'onetrust-banner.js',
  // 券规范组件埋点
  '2-38': 'common-coupon.js',
  // cod新版免验证弹窗埋点
  '2-39': 'cod-verify-free-popup.js',
  // 全局弹窗队列公共埋点
  '2-40': 'glob-popup-analysis.js',
  // 卡支付前置弹窗埋点
  '2-41': 'pre-pay.js',
  // privacy 弹窗banner埋点
  '2-42': 'privacy-popup.js',
  // 下单 Saver 组件埋点
  '2-43': 'saver-product-package.js',
  // 趋势内容体
  '2-44': 'trend-carrier-content.js',
  // webpush订阅通知功能相关埋点
  '2-45': 'webpush.js'
}
